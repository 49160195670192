<template>
  <div>
    <header-slot />
    <!-- <MaintenanceSettings /> -->
    <b-nav pills class="m-0">
      <b-nav-item
        :to="{ name: 'log-inventory-settings' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Inventory
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'log-claims-settings' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Claims
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>

  </div>
</template>
<script>
// import MaintenanceSettings from "@/views/logistic/views/settings/components/MaintenanceSettings.vue";
export default {
  components: {
  // MaintenanceSettings
  },
};

</script>
